import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL; //? API URL
axios.defaults.timeout = 30000; // ? Max time to wait for response ~30sec

axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

export default {
  axios,
};
